import { Parse } from '@/lib/parser';
import { cn } from '@/utils/helpers/cn';
import { FooterSettingsFragFragmentDoc } from '@graphqlTypes/graphql';

export const Footer = ( {
	className,
	content,
}: {
	className?: string;
	content?: string | null;
} ) => {
	if ( ! content ) {
		return null;
	}

	return (
		<footer
			role="contentinfo"
			className={ cn(
				'has-background-background-color has-small-font-size h-20 p-4 text-center',
				className
			) }
		>
			<Parse html={ content } />
		</footer>
	);
};

Footer.fragments = {
	entry: FooterSettingsFragFragmentDoc,
	key: 'FooterSettingsFrag',
};
